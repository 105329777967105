import React, { useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Spinner, TextInput } from '@project/components'
import { useIsAdminUser } from 'utils/hooks/useIsAdminUser'
import { useLabellingJobs } from 'api'
import { CreateJobButton } from './partials/CreateJobButton'
import { CreateJobPopup } from './partials/CreateJobPopup'
import { DeleteJobPopup } from './partials/DeleteJobPopup'
import { JobsTable } from './partials/JobsTable/JobsTable'
import style from './JobsPage.module.scss'

export const JobsPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isAdminUser = useIsAdminUser()
  const [jobs, loading] = useLabellingJobs()
  const [propertyFilterValue, setPropertyFilterValue] = useState('')

  if (loading) return <Spinner />

  return (
    <>
      <div className={style['top-bar']}>
        <TextInput
          label={t('table.filter.filterByPropertyId')}
          value={propertyFilterValue}
          onChange={e => setPropertyFilterValue(e.target.value)}
        />
        {isAdminUser && (
          <>
            <CreateJobButton onClick={() => navigate('create-job')} />
            <Routes>
              <Route path="create-job" element={<CreateJobPopup onClose={() => navigate('.')} />} />
              <Route
                path="delete/:propertyId/:parcelNumber/:setNumber/*"
                element={<DeleteJobPopup onClose={() => navigate('.')} />}
              />
            </Routes>
          </>
        )}
      </div>
      <JobsTable jobs={jobs} propertyFilterValue={propertyFilterValue} />
    </>
  )
}
