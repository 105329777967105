import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'
import { useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import {
  Dialog,
  DialogClose,
  DialogHeader,
  DialogContent,
  DialogButtons,
  DialogText,
  Button,
  Spacer,
} from '@project/components'
import { pushNotification } from '@project/notifications'
import { deleteLabellingJob } from 'api/requests'
import { useLabellingJobs } from 'api'

export const DeleteJobPopup = ({ onClose }) => {
  const { t } = useTranslation()
  const { propertyId, parcelNumber, setNumber } = useParams()
  const refreshLabellingJobs = useLabellingJobs()[4]

  const [{ loading }, onSubmit] = useAsyncFn(async () => {
    try {
      await deleteLabellingJob(propertyId, parcelNumber, setNumber)
      await new Promise(resolve => setTimeout(resolve, 2000))
      await refreshLabellingJobs(true, true)
      onClose()
    } catch (error) {
      pushNotification({ message: t('notification.requestFailed'), type: 'error', timeout: 1500 })
    }
  }, [refreshLabellingJobs])

  return (
    <Dialog loading={loading}>
      <DialogClose onClose={onClose} />
      <DialogHeader>{t('dialog.deleteJob.title')}</DialogHeader>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DialogText>
                {t('dialog.deleteJob.description')} {propertyId}/{parcelNumber}/{setNumber}?
              </DialogText>
              <Spacer height={30} />
              <DialogButtons>
                <Button onClick={onClose} disabled={loading} transparent>
                  {t('common.cancel')}
                </Button>
                <Button type="submit" disabled={loading}>
                  {t('common.ok')}
                </Button>
              </DialogButtons>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}
