import React, { useRef, useState } from 'react'
import classnames from 'classnames'
import * as _ from 'lodash'
import style from './Slider.module.scss'

export const Slider = ({ value, onChange, color }) => {
  const trackRef = useRef(null)
  const [active, setActive] = useState(false)

  return (
    <div className={classnames(style['slider'], { [style['slider--active']]: active })}>
      <div
        className={style['thumb']}
        style={{
          bottom: `${value * 100}%`,
          background: color,
        }}
        onMouseDown={() => setActive(true)}
      />
      {active && (
        <>
          <div ref={trackRef} className={style['reference-track']} />
          <div
            className={style['interaction-track']}
            onMouseUp={() => setActive(false)}
            onMouseLeave={() => setActive(false)}
            onMouseMove={e => {
              const element = trackRef.current
              if (element) {
                const y = e.clientY
                const { top, bottom } = element.getBoundingClientRect()
                onChange(_.clamp((y - bottom) / (top - bottom), 0, 1))
              }
            }}
          />
        </>
      )}
    </div>
  )
}
