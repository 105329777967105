import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { useQueryParams } from '@project/routing'
import { useLabellingSetInstances, useLabellingResults } from 'api'
import { Spacer, Spinner } from '@project/components'
import { Breadcrumbs } from './partials/Breadcrumbs'
import { Progress } from './partials/Progress'
import { Tool } from './partials/Tool'
import './redux/configureStore'

export const LabellingPage = () => {
  const { t } = useTranslation()
  const { setId, jobId } = useParams()
  const [{ instanceId }, setQueryParams] = useQueryParams()
  const [instances] = useLabellingSetInstances(setId)
  const [results] = useLabellingResults(jobId)

  useEffect(() => {
    if (instances && instances.length && results && !instanceId) {
      const ids = instances.map(item => item.instanceId)
      const processedIds = results.map(item => item.instanceId)
      const diff = _.difference(ids, processedIds)
      setQueryParams({ instanceId: diff[0] || ids[0] }, { replace: true })
    }
  }, [instances, results, instanceId, setQueryParams])

  if (instances && instances.length === 0)
    return (
      <>
        <Breadcrumbs />
        <Spacer height={20} />
        <div>{t('fallback.nothingToDisplay')}</div>
      </>
    )

  if (!instances || !instanceId) return <Spinner />

  return (
    <div>
      <Breadcrumbs />
      <Spacer height={10} />
      <Progress />
      <Tool />
    </div>
  )
}
