import React from 'react'
import { compose } from 'redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PageHeader, PageContent, Tab, Tabs, ErrorBoundary } from '@project/components'
import { withStore } from '@project/redux'
import { withRouter } from '@project/routing'
import { withAppTheme } from '@project/theme'
import { withAuth } from '@project/auth'
import { useIsAdminUser } from 'utils/hooks/useIsAdminUser'
import { JobsPage } from 'components/pages/JobsPage'
import { SetsPage } from 'components/pages/SetsPage'
import { LabellingPage } from 'components/pages/LabellingPage'
import style from './App.module.scss'

const App = () => {
  const { t } = useTranslation()
  const isAdminUser = useIsAdminUser()
  return (
    <ErrorBoundary>
      <PageHeader />
      <PageContent>
        <Tabs>
          <Tab to="jobs" label={t('navigation.jobs')} />
          {isAdminUser && (
            <>
              <Tab to="sets" label={t('navigation.sets')} />
            </>
          )}
        </Tabs>
        <div className={style['tab-content']}>
          <Routes>
            <Route path="jobs/*" element={<JobsPage />} />
            <Route path="jobs/labelling/:setId/:jobId/*" element={<LabellingPage />} />
            {isAdminUser && <Route path="sets/*" element={<SetsPage />} />}
            <Route path="*" element={<Navigate to="jobs" />} />
          </Routes>
        </div>
      </PageContent>
    </ErrorBoundary>
  )
}

export default compose(withAppTheme, withRouter, withStore, withAuth)(App)
