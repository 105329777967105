import React from 'react'
import { useParams } from 'react-router-dom'
import { ProgressIndicator } from '@project/components'
import { useLabellingSetInstances, useLabellingResults } from 'api'

export const Progress = () => {
  const { setId, jobId } = useParams()
  const [instances] = useLabellingSetInstances(setId)
  const [results] = useLabellingResults(jobId)

  if (!instances || !results) return null

  return <ProgressIndicator count={results.length} totat={instances.length} />
}
