import { authorizedFetch } from '@project/lib'
import { getUser } from '@project/auth'

const API = process.env.REACT_APP_GRAPHQL_API

const doQuery = async (query, variables) => {
  const response = await authorizedFetch(API, { method: 'POST', body: JSON.stringify({ query, variables }) })
  const { data, errors } = await response.json()
  if (errors) {
    console.error('GraphQL failed with the following errors:')
    errors.forEach(e => console.error(e.message))
    throw new Error(errors[0].message)
  }
  return data
}

export const getLabellingJobs = async () => {
  const query = `
    query ($exclusiveStartKey: ExclusiveStartKey) {
      labellingJobs (exclusiveStartKey: $exclusiveStartKey) {
        count
        lastEvaluatedKey {
          setId
          propertyId
          parcelNumber
          instanceId
          userId
        }
        data {
          jobId
          createdAt
          setId
          propertyId
          parcelNumber
          setNumber
          processingCount
          completedCount
        }
      }
    }
  `

  const response = await doQuery(query)
  return response.labellingJobs.data
}

export const createLabellingJob = async (labellingSetId, userGroupName) => {
  const query = `
    mutation ($labellingSetId: String!, $userGroupName: String!) {
      createLabellingJob (labellingSetId: $labellingSetId, userGroupName: $userGroupName) {
        labellingJobId
      }
    }
  `
  const variables = { labellingSetId, userGroupName }
  const response = await doQuery(query, variables)
  return response.createLabellingJob.data
}

export const deleteLabellingJob = async (propertyId, parcelNumber, setNumber) => {
  const query = `
    mutation ($propertyId: String!, $parcelNumber: String!, $setNumber: String!) {
      deleteLabellingJob (propertyId: $propertyId, parcelNumber: $parcelNumber, setNumber: $setNumber) {
        success
      }
    }
  `
  const variables = { propertyId, parcelNumber, setNumber }
  const response = await doQuery(query, variables)
  return response.deleteLabellingJob.data
}

export const getUserGroups = async () => {
  const query = `
    query {
      userGroups {
        groupName
        description
        creationDate
      }
    }
  `

  const response = await doQuery(query)
  return response.userGroups
}

export const getLabellingSets = async () => {
  const query = `
    query ($exclusiveStartKey: ExclusiveStartKey) {
      labellingSets(exclusiveStartKey: $exclusiveStartKey) {
        count
        lastEvaluatedKey {
          setId
          propertyId
          parcelNumber
          instanceId
          userId
        }
        data {
          setId
          propertyId
          parcelNumber
          setNumber
          algorithmPipelineJobId
          algorithmPipelineDate
          status
          env
        }
      }
    }
  `

  const response = await doQuery(query)
  return response.labellingSets.data
}

export const createLabellingSet = async (algorithmPipelineJobId, env, numberOfInstances, propertyId) => {
  const query = `
    mutation ($algorithmPipelineJobId: String!, $env: SdlcEnvironment!, $numberOfInstances: Int!, $propertyId: String!) {
      createLabellingSet (algorithmPipelineJobId: $algorithmPipelineJobId, env: $env, numberOfInstances: $numberOfInstances, propertyId: $propertyId) {
        labellingSetId
      }
    }
  `
  const variables = { algorithmPipelineJobId, env, numberOfInstances, propertyId }
  const response = await doQuery(query, variables)
  return response.createLabellingSet.data
}

export const getLabellingSetInstances = async setId => {
  const query = `
    query ($setId: String) {
      labellingSets(setId: $setId) {
        count
        data {
          instances {
            tileId
            propertyId
            parcelNumber
            setNumber
            s3KeyLasFile
            photos
            numberOfPoints
            zDiff
            speciesLabelPredicted
            canopyLimitDeadPredicted
            canopyLimitAlivePredicted
          }
        }
      }
    }
  `
  const variables = { setId }
  const response = await doQuery(query, variables)
  const instances = response.labellingSets.data[0]?.instances
  return instances.map(item => ({
    ...item,
    instanceId: item.s3KeyLasFile.split('.')[0].split('/').pop(),
    canopyLimitDeadPredicted: item.canopyLimitDeadPredicted === -99 ? null : item.canopyLimitDeadPredicted,
    canopyLimitAlivePredicted: item.canopyLimitAlivePredicted === -99 ? null : item.canopyLimitAlivePredicted,
  }))
}

export const signS3Object = async objectKey => {
  const query = `
    mutation ($objectKey: String!) {
      signS3Object(objectKey: $objectKey) {
        url
      }
    }
  `
  const variables = { objectKey }
  const response = await doQuery(query, variables)
  return response.signS3Object.url
}

export const createLabellingResult = async (
  setId,
  labellingJobId,
  s3KeyLasFile,
  canopyLimitDead,
  canopyLimitAlive,
  leafOn,
  speciesLabel,
  treeQuality,
  overallQuality,
  segmentationQuality,
  pointCloudQuality,
  phenoPhase,
  userComment,
) => {
  const query = `
    mutation ($data: LabellingResultInput!) {
      createLabellingResult(data: $data) {
        success
      }
    }
  `
  const data = {
    setId,
    labellingJobId,
    s3KeyLasFile,
    canopyLimitDead,
    canopyLimitAlive,
    leafOn,
    speciesLabel,
    treeQuality,
    overallQuality,
    segmentationQuality,
    pointCloudQuality,
    phenoPhase,
    userComment,
  }

  const variables = { data }
  const response = await doQuery(query, variables)
  return response.createLabellingResult.success
}

export const getLabellingResults = async labellingJobId => {
  const query = `
    query ($labellingJobId: String!, $userId: String, $exclusiveStartKey: ExclusiveStartKey) {
      labellingResults(labellingJobId: $labellingJobId, userId: $userId, exclusiveStartKey: $exclusiveStartKey) {
        count
        lastEvaluatedKey {
          instanceId
          labellingJobIdUserId
          labellingJobIdInstanceId
          userId
        }
        data {
          userId
          createdAt
          s3KeyLasFile
          speciesLabel
          userComment
          leafOn
          treeQuality
          overallQuality,
          segmentationQuality,
          pointCloudQuality
          phenoPhase
          canopyLimitDead
          canopyLimitAlive
        }
      }
    }
  `

  const results = []
  const variables = { labellingJobId, userId: getUser().attributes.sub }

  for (;;) {
    const response = await doQuery(query, variables)
    const { data, lastEvaluatedKey } = response.labellingResults
    results.push(...data)
    variables.exclusiveStartKey = lastEvaluatedKey
    if (!lastEvaluatedKey) {
      return results.map(item => ({ ...item, instanceId: item.s3KeyLasFile.split('.')[0].split('/').pop() }))
    }
  }
}
