import React from 'react'
import { useNavigate } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { HtmlTable } from '@project/components'
import { useIsAdminUser } from 'utils/hooks/useIsAdminUser'
import { DeleteJobButton } from '../DeleteJobButton'

const getColumns = t => [
  {
    Header: t('table.header.job'),
    Cell: ({ original }) => {
      const { setId, jobId, propertyId, parcelNumber, setNumber } = original
      const name = `${propertyId} / ${parcelNumber} / ${setNumber}`
      return <NavLink to={`/jobs/labelling/${setId}/${jobId}`}>{name}</NavLink>
    },
    link: true,
  },
  {
    Header: t('table.header.jobId'),
    Cell: ({ original }) => {
      const { jobId } = original
      return jobId
    },
  },
  {
    Header: t('table.header.status'),
    Cell: ({ original }) => {
      const { processingCount, completedCount } = original
      return `In process: ${processingCount}, completed: ${completedCount}`
    },
  },
  {
    Cell: ({ original }) => {
      const { propertyId, parcelNumber, setNumber } = original
      const navigate = useNavigate()
      const isAdminUser = useIsAdminUser()

      return isAdminUser ? (
        <DeleteJobButton onClick={() => navigate(`delete/${propertyId}/${parcelNumber}/${setNumber}`)} />
      ) : (
        ''
      )
    },
    alignRight: true,
  },
]

export const JobsTable = ({ jobs, propertyFilterValue }) => {
  const { t } = useTranslation()

  const data = jobs?.filter(job => job.propertyId.startsWith(propertyFilterValue))

  if (!data || data.length === 0) return t('fallback.nothingToDisplay')

  return <HtmlTable data={data} columns={getColumns(t)} />
}
