import React, { useState } from 'react'
import classnames from 'classnames'
import style from './ImageGallery.module.scss'

export const ImageGallery = ({ urls }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  if (!urls) return null

  if (!urls.length)
    return (
      <div className={style['placeholder']}>
        <i className="fa fa-eye-slash" />
      </div>
    )

  const activeUrl = urls[activeIndex] || urls[0]

  return (
    <div className={style['image-gallery']}>
      <div
        className={style['pane']}
        onClick={() => setActiveIndex(activeIndex < urls.length - 1 ? activeIndex + 1 : 0)}
      >
        <img className={style['invisible-image']} src={activeUrl} alt="aerial-view" />
        <img className={style['image']} src={activeUrl} alt="aerial-view" />
      </div>
      <div className={style['thumbs']}>
        {urls.map((url, index) => (
          <div
            key={url}
            className={classnames({
              [style['thumb']]: true,
              [style['thumb--active']]: url === activeUrl,
            })}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </div>
    </div>
  )
}
