import React from 'react'
import { useParams } from 'react-router-dom'
import { useQueryParams } from '@project/routing'
import { useLabellingSetInstances } from 'api'
import style from './Navigator.module.scss'

export const Navigator = () => {
  const { setId } = useParams()
  const [{ instanceId }, setQueryParams] = useQueryParams()
  const [instances] = useLabellingSetInstances(setId)

  if (!instances || !instances.length) return null

  return (
    <div className={style['navigator']}>
      <div
        className={style['control']}
        onClick={() => {
          const index = instances.findIndex(item => item.instanceId === instanceId)
          if (index > 0) setQueryParams({ instanceId: instances[index - 1].instanceId })
        }}
      >
        <i className="fa fa-angle-left" />
        <span>prev.</span>
      </div>
      <div
        className={style['control']}
        onClick={() => {
          const index = instances.findIndex(item => item.instanceId === instanceId)
          if (index < instances.length - 1) setQueryParams({ instanceId: instances[index + 1].instanceId })
        }}
      >
        <span>next</span>
        <i className="fa fa-angle-right" />
      </div>
    </div>
  )
}
