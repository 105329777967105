import { uid } from 'react-uid'
import { useDataLoader } from '@project/api'
import { signS3Object } from '../../requests'
import { useLabellingSetInstance } from './useLabellingSetInstance'

const hookId = uid({})

export const useAerialPhotoUrls = (setId, instanceId) => {
  const [instance] = useLabellingSetInstance(setId, instanceId)
  const { photos } = instance || {}
  const getData = photos && (() => Promise.all(photos.map(s3Key => signS3Object(s3Key))))
  return useDataLoader(hookId, getData, [setId, instanceId])
}
