import { PHENO_PHASE } from 'utils/lib/phenoPhase'

const SET_CANOPY_LIMIT_DEAD = '@labelling/set-canopy-limit-dead'
const SET_CANOPY_LIMIT_ALIVE = '@labelling/set-canopy-limit-ALIVE'
const SET_LEAF_ON = '@labelling/set-leaf-on'
const SET_TREE_QUALITY = '@labelling/set-tree-quality'
const SET_OVERALL_QUALITY = '@labelling/set-overall-quality'
const SET_SEGMENTATION_QUALITY = '@labelling/set-segmentation-quality'
const SET_POINTCLOUD_QUALITY = '@labelling/set-pointcloud-quality'
const SET_SPECIES_LABEL = '@labelling/set-species-label'
const SET_PHENO_PHASE = '@labelling/set-pheno-phase'
const SET_USER_COMMENT = '@labelling/set-user-comment'
const RESET = '@labelling/reset'

const initialState = {
  canopyLimitDead: null,
  canopyLimitAlive: null,
  leafOn: true,
  treeQuality: null,
  speciesLabel: null,
  phenoPhase: PHENO_PHASE.LEAF_ON,
  userComment: '',
  overallQuality: null,
  segmentationQuality: null,
  pointCloudQuality: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CANOPY_LIMIT_DEAD: {
      return { ...state, canopyLimitDead: action.canopyLimitDead }
    }
    case SET_CANOPY_LIMIT_ALIVE: {
      return { ...state, canopyLimitAlive: action.canopyLimitAlive }
    }
    case SET_LEAF_ON: {
      return { ...state, leafOn: action.leafOn }
    }
    case SET_TREE_QUALITY: {
      return { ...state, treeQuality: action.treeQuality }
    }
    case SET_OVERALL_QUALITY: {
      return { ...state, overallQuality: action.overallQuality }
    }
    case SET_SEGMENTATION_QUALITY: {
      return { ...state, segmentationQuality: action.segmentationQuality }
    }
    case SET_POINTCLOUD_QUALITY: {
      return { ...state, pointCloudQuality: action.pointCloudQuality }
    }
    case SET_SPECIES_LABEL: {
      return { ...state, speciesLabel: action.speciesLabel }
    }
    case SET_PHENO_PHASE: {
      return { ...state, phenoPhase: action.phenoPhase }
    }
    case SET_USER_COMMENT: {
      return { ...state, userComment: action.userComment }
    }
    case RESET: {
      return initialState
    }
    default:
      return state
  }
}

export const setCanopyLimitDead = canopyLimitDead => ({ type: SET_CANOPY_LIMIT_DEAD, canopyLimitDead })

export const setCanopyLimitAlive = canopyLimitAlive => ({ type: SET_CANOPY_LIMIT_ALIVE, canopyLimitAlive })

export const setLeafOn = leafOn => ({ type: SET_LEAF_ON, leafOn })

export const setTreeQuality = treeQuality => ({ type: SET_TREE_QUALITY, treeQuality })

export const setOverallQuality = overallQuality => ({ type: SET_OVERALL_QUALITY, overallQuality })

export const setSegmentationQuality = segmentationQuality => ({ type: SET_SEGMENTATION_QUALITY, segmentationQuality })

export const setPointcloudQuality = pointCloudQuality => ({ type: SET_POINTCLOUD_QUALITY, pointCloudQuality })

export const setSpeciesLabel = speciesLabel => ({ type: SET_SPECIES_LABEL, speciesLabel })

export const setPhenophase = phenoPhase => ({ type: SET_PHENO_PHASE, phenoPhase })

export const setUserComment = userComment => ({ type: SET_USER_COMMENT, userComment })

export const reset = () => ({ type: RESET })

export const restore = (instance, result) => dispatch => {
  if (!instance) return

  dispatch(reset())

  const { zDiff } = instance

  if (result) {
    const {
      canopyLimitDead,
      canopyLimitAlive,
      leafOn,
      treeQuality,
      overallQuality,
      segmentationQuality,
      pointCloudQuality,
      speciesLabel,
      phenoPhase,
      userComment,
    } = result

    if (canopyLimitDead !== null) dispatch(setCanopyLimitDead(canopyLimitDead / zDiff))
    if (canopyLimitAlive !== null) dispatch(setCanopyLimitAlive(canopyLimitAlive / zDiff))
    dispatch(setLeafOn(leafOn))
    dispatch(setTreeQuality(treeQuality))
    dispatch(setOverallQuality(overallQuality))
    dispatch(setSegmentationQuality(segmentationQuality))
    dispatch(setPointcloudQuality(pointCloudQuality))
    dispatch(setSpeciesLabel(speciesLabel))
    dispatch(setPhenophase(phenoPhase))
    dispatch(setUserComment(userComment))
  } else {
    const { canopyLimitDeadPredicted, canopyLimitAlivePredicted, speciesLabelPredicted } = instance

    if (canopyLimitDeadPredicted !== null) dispatch(setCanopyLimitDead(canopyLimitDeadPredicted / zDiff))
    if (canopyLimitAlivePredicted !== null) dispatch(setCanopyLimitAlive(canopyLimitAlivePredicted / zDiff))
    dispatch(setSpeciesLabel(speciesLabelPredicted || null))
  }
}
