import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useQueryParams } from '@project/routing'
import { useTile, PlaneIndicator } from '@project/pointcloud3D'
import { useLabellingSetInstance } from 'api'
import { setCanopyLimitDead, setCanopyLimitAlive } from '../../../../../../redux/ducks/labelling'
import { Bar } from './partials/Bar'
import { Scale } from './partials/Scale'
import { Slider } from './partials/Slider'

export const Ruler = () => {
  const dispatch = useDispatch()
  const { setId } = useParams()
  const [{ instanceId }] = useQueryParams()
  const [instance] = useLabellingSetInstance(setId, instanceId)
  const tile = useTile()

  const canopyLimitDead = useSelector(store => store.labelling.canopyLimitDead)
  const canopyLimitAlive = useSelector(store => store.labelling.canopyLimitAlive)

  if (!tile || !instance) return null

  if (canopyLimitDead === null && canopyLimitAlive === null) return null

  return (
    <>
      <Bar>
        <Scale height={instance.zDiff} />
        {canopyLimitDead !== null && (
          <>
            <Slider value={canopyLimitDead} onChange={value => dispatch(setCanopyLimitDead(value))} color="#b30000" />
            <PlaneIndicator value={canopyLimitDead} color="#b30000" />
          </>
        )}
        {canopyLimitAlive !== null && (
          <>
            <Slider value={canopyLimitAlive} onChange={value => dispatch(setCanopyLimitAlive(value))} color="#015a01" />
            <PlaneIndicator value={canopyLimitAlive} color="#015a01" />
          </>
        )}
      </Bar>
    </>
  )
}
