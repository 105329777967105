import React from 'react'
import { useParams } from 'react-router-dom'
import { useQueryParams } from '@project/routing'
import { Spinner } from '@project/components'
import { useAerialPhotoUrls } from 'api'
import { ImageGallery } from './partials/ImageGallery'

export const AerialView = () => {
  const { setId } = useParams()
  const [{ instanceId }] = useQueryParams()
  const [urls, loading] = useAerialPhotoUrls(setId, instanceId)

  if (!urls || loading) return <Spinner />

  return <ImageGallery urls={urls} />
}
