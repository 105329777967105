import React from 'react'
import ReactDOM from 'react-dom'
import { Spinner } from '@project/components'
import { usePaneUtils } from '@project/pointcloud3D'
import style from './Progress.module.scss'

export const Progress = () => {
  const paneUtils = usePaneUtils()
  return ReactDOM.createPortal(<Spinner className={style['spinner']} />, paneUtils.getPaneContainer())
}
