import { useLabellingSetInstance } from './useLabellingSetInstance'
import { useLabellingResults } from './useLabellingResults'

export const useLabellingResult = (setId, jobId, instanceId) => {
  const [instance, instanceLoading, instanceError] = useLabellingSetInstance(setId, instanceId)
  const [results, resultsLoading, resultsError] = useLabellingResults(jobId)
  const { s3KeyLasFile } = instance || {}
  const result = results?.find(item => item.s3KeyLasFile === s3KeyLasFile)
  return [result, instanceLoading || resultsLoading, instanceError || resultsError]
}
