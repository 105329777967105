import { uid } from 'react-uid'
import { useDataLoader } from '@project/api'
import { LasFile } from '@project/lib'
import { signS3Object } from '../../requests'
import { useLabellingSetInstance } from './useLabellingSetInstance'

const hookId = uid({})

const fetchData = async s3KeyLasFile => {
  const url = await signS3Object(s3KeyLasFile)
  const response = await fetch(url)
  const arrayBuffer = await response.arrayBuffer()
  const data = new Uint8Array(arrayBuffer)
  const lasFile = new LasFile()
  lasFile.addBufferData(data)
  return lasFile.getData()
}

export const usePointcloud = (setId, instanceId) => {
  const [instance] = useLabellingSetInstance(setId, instanceId)
  const { s3KeyLasFile } = instance || {}
  const getData = s3KeyLasFile && fetchData
  return useDataLoader(hookId, getData, [s3KeyLasFile])
}
