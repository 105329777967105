export default {
  page: {
    title: 'Labelling tool',
  },

  common: {
    cancel: 'Cancel',
    ok: 'Ok',
  },

  navigation: {
    jobs: 'Jobs',
    sets: 'Sets',
    usersAndGroups: 'Users and Groups',
    labellingJobs: 'Labelling jobs',
  },

  dialog: {
    trigger: {
      createJob: 'Create new job',
      createSet: 'Create new set',
      deleteJob: 'Delete job',
    },

    createJob: {
      title: 'Create a labelling job',
      selectLabellingSet: 'Select labelling set',
      assignToUserGroup: 'Assign job to user group',
    },

    deleteJob: {
      title: 'Delete job',
      description: 'Do you confirm the delition of job',
    },

    createSet: {
      title: 'Create a labelling set',
      propertyId: 'Property ID',
      algorithmPipelineJobId: 'Algorithm pipeline job ID',
      numberOfInstances: 'Number of elements to pick randomly',
      environment: 'Environment',
    },
  },

  table: {
    filter: {
      filterByPropertyId: 'Filter by property id',
    },

    header: {
      job: 'Property / parcel / labelling set',
      status: 'Status',
      set: 'Set',
      algorithmPipelineJob: 'Algorithm pipeline job',
      jobId: 'labelling job id',
    },
  },

  status: {
    PENDING: 'Pending',
    RUNNING: 'Running',
    SUCCEEDED: 'Succeeded',
    FAILED: 'Failed',
  },

  pointcloud3D: {
    placeholder: 'Choose a map tile to see 3D view.',
  },

  tool: {
    label: {
      canopyLimitDead: 'Canopy limit dead',
      canopyLimitAlive: 'Canopy limit alive',
      species: 'Species',
      treeQuality: 'Tree quality',
      overallQuality: 'Overall quality',
      segmentationQuality: 'Segmentation quality',
      pointcloudQuality: 'Pointcloud quality',
      phenoPhase: 'Phenophase',
    },
  },

  treeQuality: {
    GOOD: 'good',
    SNOW_DAMAGE: 'snow damage',
    BRANCHY: 'branchy',
    CURVY_TRUNK: 'curvy trunk',
    DEAD: 'dead',
    NO_DATA: 'no data',
  },

  overallQuality: {
    GOOD: 'good',
    NORMAL: 'normal',
    POOR: 'poor',
    UNUSABLE: 'unusable',
  },

  segmentationQuality: {
    GOOD: 'good',
    EXTRA_SEGMENTATION: 'extra segmentation',
    MISSING_SEGMENTATION: 'missing segmentation',
  },

  pointcloudQuality: {
    NO_DEFECTS: 'no defects',
    SPARSE: 'sparse',
    NOISY: 'noisy',
  },

  phenoPhase: {
    LEAF_BURST: 'leaf burst',
    LEAF_ON: 'leaf on',
    LEAF_DROP: 'leaf drop',
    LEAF_OFF: 'leaf off',
  },

  fallback: {
    nothingToDisplay: 'Nothing to display.',
  },

  notification: {
    requestFailed: 'The request has failed.',
  },
}
