import React from 'react'
import { Checkbox } from '@project/components'
import style from './IndicatorLegend.module.scss'

export const IndicatorLegend = ({ label, value, color, onChange }) => (
  <div className={style['container']}>
    <Checkbox checked={value !== null} onChange={onChange} />
    <div className={style['rect']} style={{ background: color }} />
    <div className={style['label']}>
      {label}: <b>{value !== null ? value.toFixed(2) : '—'}</b>
    </div>
  </div>
)
