import React from 'react'
import classnames from 'classnames'
import { Dropdown } from '@project/components'
import style from './InlineSelect.module.scss'

const renderOptions = (options, value, onChange) =>
  options.map(({ value: optionValue, label: optionLabel }) => (
    <div
      key={optionValue}
      className={classnames(style['option'], { [style['option--active']]: optionValue === value })}
      onClick={() => onChange(optionValue)}
    >
      {optionLabel}
    </div>
  ))

export const InlineSelect = ({ label, options, value, onChange, n = 5 }) => (
  <div className={style['select']}>
    <div className={style['label']}>{label}</div>
    <div className={style['options']}>
      {renderOptions(options.slice(0, n), value, onChange)}
      {options.length > n && (
        <Dropdown
          renderTrigger={() => (
            <div
              className={classnames({
                [style['dropdown-trigger']]: true,
                [style['dropdown-trigger--active']]: options.findIndex(o => o.value === value) >= n,
              })}
            >
              ...
            </div>
          )}
          renderContent={() => (
            <div className={style['dropdown-content']}>
              {renderOptions(options.slice(n, options.length), value, onChange)}
            </div>
          )}
        />
      )}
    </div>
  </div>
)
