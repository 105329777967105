import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useQueryParams } from '@project/routing'
import { useLabellingSetInstance, useLabellingResult } from 'api'
import { restore } from '../../redux/ducks/labelling'
import { Pointcloud3D } from './partials/Pointcloud3D'
import { SubmitForm } from './partials/SubmitForm'
import { Navigator } from './partials/Navigator'
import style from './Tool.module.scss'
import { AerialView } from './partials/AerialView'

export const Tool = () => {
  const { setId, jobId } = useParams()
  const [{ instanceId }] = useQueryParams()
  const dispatch = useDispatch()
  const [instance] = useLabellingSetInstance(setId, instanceId)
  const [result] = useLabellingResult(setId, jobId, instanceId)

  useEffect(() => dispatch(restore(instance, result)), [instance, result, dispatch])

  if (!instance) return null

  return (
    <div className={style['container']}>
      <div className={style['visualization-section']}>
        <div className={style['aerial-view']}>
          <AerialView />
        </div>
        <div className={style['pointcloud-3d']}>
          <Pointcloud3D />
        </div>
      </div>
      <div className={style['form-section']}>
        <Navigator />
        <SubmitForm />
      </div>
    </div>
  )
}
