import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'
import { Form, Field } from 'react-final-form'
import {
  Dialog,
  DialogClose,
  DialogHeader,
  DialogContent,
  DialogButtons,
  Button,
  Select,
  FormControl,
  FormControlMessage,
} from '@project/components'
import { requiredValidator } from '@project/validation'
import { pushNotification } from '@project/notifications'
import { createLabellingJob } from 'api/requests'
import { useUserGroups, useLabellingSets, useLabellingJobs } from 'api'

export const CreateJobPopup = ({ onClose }) => {
  const { t } = useTranslation()

  const [sets, loadingSets] = useLabellingSets()
  const [userGroups, loadingUserGroups] = useUserGroups()
  const refreshLabellingJobs = useLabellingJobs()[4]

  const [{ loading }, onSubmit] = useAsyncFn(
    async fields => {
      const { labellingSetId, userGroupName } = fields
      try {
        await createLabellingJob(labellingSetId, userGroupName)
        await new Promise(resolve => setTimeout(resolve, 2000))
        await refreshLabellingJobs(true, true)
        onClose()
      } catch (error) {
        pushNotification({ message: t('notification.requestFailed'), type: 'error', timeout: 1500 })
      }
    },
    [refreshLabellingJobs],
  )

  return (
    <Dialog loading={loading || loadingSets || loadingUserGroups}>
      <DialogClose onClose={onClose} />
      <DialogHeader>{t('dialog.createJob.title')}</DialogHeader>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormControl>
                <Field
                  name="labellingSetId"
                  validate={requiredValidator}
                  render={({ input, meta }) => (
                    <>
                      <Select
                        label={t('dialog.createJob.selectLabellingSet')}
                        options={sets?.map(set => {
                          const { setId, propertyId, parcelNumber, setNumber } = set
                          return { label: `${propertyId} / ${parcelNumber} / ${setNumber}`, value: setId }
                        })}
                        {...input}
                      />
                      {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                    </>
                  )}
                />
              </FormControl>
              <FormControl>
                <Field
                  name="userGroupName"
                  validate={requiredValidator}
                  render={({ input, meta }) => (
                    <>
                      <Select
                        label={t('dialog.createJob.assignToUserGroup')}
                        options={userGroups?.map(({ groupName }) => ({ label: groupName, value: groupName }))}
                        {...input}
                      />
                      {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                    </>
                  )}
                />
              </FormControl>
              <DialogButtons>
                <Button onClick={onClose} disabled={loading} transparent>
                  {t('common.cancel')}
                </Button>
                <Button type="submit" disabled={loading}>
                  {t('common.ok')}
                </Button>
              </DialogButtons>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}
