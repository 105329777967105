import React from 'react'
import ReactDOM from 'react-dom'
import { usePaneUtils } from '@project/pointcloud3D'
import style from './Bar.module.scss'

export const Bar = ({ children }) => {
  const paneUtils = usePaneUtils()

  return ReactDOM.createPortal(
    <div className={style['container']}>
      <div className={style['bar']}>{children}</div>
    </div>,
    paneUtils.getPaneContainer(),
  )
}
