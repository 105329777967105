import React from 'react'
import { useParams } from 'react-router-dom'
import { useQueryParams } from '@project/routing'
import { Pointcloud3D as P3D, OrbitControls, Points, BBox } from '@project/pointcloud3D'
import { usePointcloud } from 'api'
import { Ruler } from './partials/Ruler'
import { Progress } from './partials/Progress'

export const Pointcloud3D = () => {
  const { setId } = useParams()
  const [{ instanceId }] = useQueryParams()
  const [tile, loading] = usePointcloud(setId, instanceId)
  return (
    <P3D tile={tile}>
      <OrbitControls />
      <Points />
      <BBox />
      <Ruler />
      {!tile || (loading && <Progress />)}
    </P3D>
  )
}
