import { SPECIES as SP } from '@project/ontology'

const { BETULA_SPP, PICEA_ABIES, PINUS_SYLVESTRIS, POPULUS_TREMULA, UNKNOWN, ...rest } = SP

export const SPECIES = Object.freeze({
  BETULA_SPP,
  PICEA_ABIES,
  PINUS_SYLVESTRIS,
  POPULUS_TREMULA,
  UNKNOWN,
  ...rest,
})
