import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'
import { useQueryParams } from '@project/routing'
import { getEntityCaption } from '@project/ontology'
import { pushNotification } from '@project/notifications'
import { Button, Spacer, Spinner } from '@project/components'
import { useLabellingSetInstance, useLabellingResults } from 'api'
import { createLabellingResult } from 'api/requests'
import { SPECIES } from 'utils/lib/species'
import { TREE_QUALITY } from 'utils/lib/treeQuality'
import { OVERALL_QUALITY } from 'utils/lib/overallQuality'
import { SEGMENTATION_QUALITY } from 'utils/lib/segmentationQuality'
import { POINTCLOUD_QUALITY } from 'utils/lib/pointcloudQuality'
import { PHENO_PHASE } from 'utils/lib/phenoPhase'
import style from './SubmitForm.module.scss'

import {
  setCanopyLimitDead,
  setCanopyLimitAlive,
  setSpeciesLabel,
  setPointcloudQuality,
  setTreeQuality,
  setPhenophase,
  setUserComment,
  setOverallQuality,
  setSegmentationQuality,
} from '../../../../redux/ducks/labelling'
import { IndicatorLegend } from './partials/IndicatorLegend'
import { InlineSelect } from './partials/InlineSelect'

export const SubmitForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    canopyLimitDead,
    canopyLimitAlive,
    leafOn,
    treeQuality,
    overallQuality,
    segmentationQuality,
    pointCloudQuality,
    speciesLabel,
    phenoPhase,
    userComment,
  } = useSelector(store => store.labelling)
  const { setId, jobId } = useParams()
  const [{ instanceId }] = useQueryParams()
  const [instance] = useLabellingSetInstance(setId, instanceId)
  const refreshLabellingResults = useLabellingResults(jobId)[4]

  const [{ loading }, onSubmit] = useAsyncFn(async () => {
    try {
      const { s3KeyLasFile, zDiff } = instance
      await createLabellingResult(
        setId,
        jobId,
        s3KeyLasFile,
        canopyLimitDead * zDiff,
        canopyLimitAlive * zDiff,
        leafOn,
        speciesLabel,
        treeQuality,
        overallQuality,
        segmentationQuality,
        pointCloudQuality,
        phenoPhase,
        userComment,
      )
      setTimeout(() => refreshLabellingResults(true, true), 1000)
    } catch (error) {
      pushNotification({ message: t('notification.requestFailed'), type: 'error', timeout: 1500 })
    }
  }, [
    instance,
    setId,
    jobId,
    canopyLimitDead,
    canopyLimitAlive,
    leafOn,
    speciesLabel,
    treeQuality,
    overallQuality,
    segmentationQuality,
    pointCloudQuality,
    userComment,
    t,
    refreshLabellingResults,
  ])

  if (!instance) return null

  return (
    <div>
      <IndicatorLegend
        label={t('tool.label.canopyLimitDead')}
        value={canopyLimitDead && canopyLimitDead * instance.zDiff}
        color="#b30000"
        onChange={e => dispatch(setCanopyLimitDead(e.target.checked ? 0 : null))}
      />

      <IndicatorLegend
        label={t('tool.label.canopyLimitAlive')}
        value={canopyLimitAlive && canopyLimitAlive * instance.zDiff}
        color="#015a01"
        onChange={e => dispatch(setCanopyLimitAlive(e.target.checked ? 1 : null))}
      />

      <Spacer height={10} />

      <InlineSelect
        label={t('tool.label.species')}
        value={speciesLabel}
        options={Object.values(SPECIES).map(v => ({ value: v, label: getEntityCaption(v, t) }))}
        onChange={value => dispatch(setSpeciesLabel(value))}
      />

      <InlineSelect
        label={t('tool.label.treeQuality')}
        value={treeQuality}
        options={Object.values(TREE_QUALITY).map(v => ({ value: v, label: t(`treeQuality.${v}`) }))}
        onChange={value => dispatch(setTreeQuality(value))}
        n={6}
      />

      <InlineSelect
        label={t('tool.label.overallQuality')}
        value={overallQuality}
        options={Object.values(OVERALL_QUALITY).map(v => ({ value: v, label: t(`overallQuality.${v}`) }))}
        onChange={value => dispatch(setOverallQuality(value))}
      />

      <InlineSelect
        label={t('tool.label.segmentationQuality')}
        value={segmentationQuality}
        options={Object.values(SEGMENTATION_QUALITY).map(v => ({ value: v, label: t(`segmentationQuality.${v}`) }))}
        onChange={value => dispatch(setSegmentationQuality(value))}
      />

      <InlineSelect
        label={t('tool.label.pointcloudQuality')}
        value={pointCloudQuality}
        options={Object.values(POINTCLOUD_QUALITY).map(v => ({ value: v, label: t(`pointcloudQuality.${v}`) }))}
        onChange={value => dispatch(setPointcloudQuality(value))}
      />

      <InlineSelect
        label={t('tool.label.phenoPhase')}
        value={phenoPhase}
        options={Object.values(PHENO_PHASE).map(v => ({ value: v, label: t(`phenoPhase.${v}`) }))}
        onChange={value => dispatch(setPhenophase(value))}
      />

      <textarea
        placeholder="Comment..."
        value={userComment}
        onChange={e => dispatch(setUserComment(e.target.value))}
        className={style['textarea']}
      />

      <Button
        disabled={!treeQuality || !overallQuality || !segmentationQuality || !pointCloudQuality || !phenoPhase}
        onClick={onSubmit}
      >
        Submit
      </Button>

      {loading && <Spinner />}
    </div>
  )
}
