import React from 'react'
import { useTranslation } from 'react-i18next'
import { HtmlTable } from '@project/components'

const getColumns = t => [
  {
    Header: t('table.header.set'),
    Cell: ({ original }) => {
      const { propertyId, parcelNumber, setNumber } = original
      return `${propertyId} / ${parcelNumber} / ${setNumber}`
    },
  },
  {
    Header: t('table.header.algorithmPipelineJob'),
    Cell: ({ original }) => {
      const { algorithmPipelineJobId, env } = original
      return `${algorithmPipelineJobId} / ${env}`
    },
  },
  {
    Header: t('table.header.status'),
    Cell: ({ original }) => t(`status.${original.status}`),
    width: 100,
  },
]

export const SetsTable = ({ sets, propertyFilterValue, statusFilterValue }) => {
  const { t } = useTranslation()

  const data = sets
    ?.filter(set => set.propertyId.startsWith(propertyFilterValue))
    .filter(set => !statusFilterValue || set.status === statusFilterValue)

  if (!data || data.length === 0) return t('fallback.nothingToDisplay')

  return <HtmlTable data={data} columns={getColumns(t)} />
}
