import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Breadcrumbs as ToolkitBreadcrumbs } from '@project/components'
import { useLabellingJob } from 'api'

export const Breadcrumbs = () => {
  const { t } = useTranslation()
  const { jobId } = useParams()
  const [job] = useLabellingJob(jobId)

  if (!job) return null

  const { propertyId, parcelNumber, setNumber } = job

  return (
    <ToolkitBreadcrumbs
      items={[
        { element: <NavLink to="/jobs">{t('navigation.labellingJobs')}</NavLink> },
        { element: `${propertyId} / ${parcelNumber} / ${setNumber}` },
      ]}
    />
  )
}
