import React, { useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { TextInput, Select, Spinner } from '@project/components'
import { STATUS } from 'utils/lib/status'
import { useLabellingSets } from 'api'
import { CreateSetButton } from './partials/CreateSetButton'
import { CreateSetPopup } from './partials/CreateSetPopup'
import { SetsTable } from './partials/SetsTable'
import style from './SetsPage.module.scss'

export const SetsPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [sets, loading] = useLabellingSets()
  const [propertyFilterValue, setPropertyFilterValue] = useState('')
  const [statusFilterValue, setStatusFilterValue] = useState('')

  if (loading) return <Spinner />

  return (
    <>
      <div className={style['top-bar']}>
        <TextInput
          label={t('table.filter.filterByPropertyId')}
          value={propertyFilterValue}
          onChange={e => setPropertyFilterValue(e.target.value)}
        />
        <Select
          value={statusFilterValue}
          options={[
            { value: '', label: 'All statuses' },
            ...Object.values(STATUS).map(value => ({ value, label: t(`status.${value}`) })),
          ]}
          onChange={value => setStatusFilterValue(value)}
        />
        <CreateSetButton onClick={() => navigate('create-job')} />
      </div>
      <SetsTable sets={sets} propertyFilterValue={propertyFilterValue} statusFilterValue={statusFilterValue} />
      <Routes>
        <Route path="create-job" element={<CreateSetPopup onClose={() => navigate('.')} />} />
      </Routes>
    </>
  )
}
