import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@project/components'

export const CreateSetButton = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <Button icon={<i className="fa fa-plus" />} onClick={onClick}>
      {t('dialog.trigger.createSet')}
    </Button>
  )
}
